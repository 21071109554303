import { ReactComponent as IconLogoLockselfV2 } from "../../assets/icons/icon-logo-lockself-v2.svg"

import PresentationPanel from "./components/PresentationPanel"

import "./style.scss"
import "./components/style.scss"
import FormPanel from "./components/FormPanel"
import LanguageSelect from "../../components/LanguageSelect"
import { useEffect, useState } from "react"
import Loader from "../../components/animations/Loader"

export default function MonoStepFunnell() {
	const [isLoaderVisible, setIsLoaderVisible] = useState(false)

	useEffect(() => {
		const wasReloaded = localStorage.getItem("isReloaded")

		if (wasReloaded) {
			localStorage.removeItem("isReloaded")
		}
	}, [])

	return (
		<div className="monoStepFunnelLayout monoStepFunnel">
			{isLoaderVisible && <Loader />}	

			<IconLogoLockselfV2 className="logo" />
			<LanguageSelect isReversed />

			<div className="contentWrapper">
				<div className="content">
					<PresentationPanel />
					<FormPanel setIsLoaderVisible={setIsLoaderVisible} />
				</div>
			</div>
		</div>
	)
}
