export default function Checkbox({ id, name = id, labelNode = null }) {
	return (
		<div className="checkbox">
			<div className="inputCheckboxContainer">
				<input className="inputCheckboxControl" type="checkbox" name={name} id={id} />
			</div>
			{labelNode && (
				<label className="inputCheckboxLabel" htmlFor={id}>
					{labelNode}
				</label>
			)}
		</div>
	)
}
