import { useTranslation } from "react-i18next"
import Checkbox from "./Checkbox"
import ClassicInput from "./ClassicInput"
import PhoneNumberInput from "./PhoneNumberInput"
import Select from "./Select"
import ErrorText from "./ErrorText"

export default function FieldItem(props) {
	const {
		isSelect = false,
		isExpanded = false,
		isPhoneNumber = false,
		isCheckbox,
		label,
		isInvalid,
		phoneNumberDataRef = null,
		isPhoneNumberValid = true,
		hasMoreHeadSpaceOnMobile = false,
		...rest
	} = props
	const [t] = useTranslation()

	function renderInput() {
		if (isSelect) {
			return <Select {...rest} />
		}

		if (isPhoneNumber) {
			return (
				<PhoneNumberInput
					{...rest}
					phoneNumberDataRef={phoneNumberDataRef}
					isPhoneNumberValid={isPhoneNumberValid}
				/>
			)
		}

		if (isCheckbox) {
			return <Checkbox {...rest} />
		}

		return <ClassicInput {...rest} />
	}

	return (
		<div
			className={`fieldItem${isInvalid || (isPhoneNumber && !isPhoneNumberValid) ? " isInvalid" : ""}${
				isExpanded ? " isExpanded" : ""
			}${isSelect ? " isSelect" : ""}${isPhoneNumber ? " isPhoneNumber" : ""}${
				hasMoreHeadSpaceOnMobile ? " hasMoreHeadSpaceOnMobile" : ""
			}`}
		>
			{!isCheckbox && !!label && (
				<label className="fieldLabel" htmlFor={rest.id}>
					{label}
				</label>
			)}
			{isInvalid && <ErrorText text={t("monoStepFunnel.formPanel.fields.error.required")} />}
			{renderInput()}
		</div>
	)
}
