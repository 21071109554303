import './style.scss'

export default function Loader() {
	return (
		<div className="loader">
			<div className="loader-container">
				<div className="loader-wrapper">
					<div id="loader"></div>
					<div className="loader-section-transparent section-left"></div>
					<div className="loader-section-transparent section-right"></div>
				</div>
			</div>
		</div>
	)
}
