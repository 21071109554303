import { ReactComponent as IconLockpass } from "../../../assets/icons/products/icon-lockpass.svg"
import { ReactComponent as IconLocktransfer } from "../../../assets/icons/products/icon-locktransfer.svg"
import { ReactComponent as IconLockfiles } from "../../../assets/icons/products/icon-lockfiles.svg"
import { ReactComponent as IconManagement } from "../../../assets/icons/products/icon-management.svg"

import { ReactComponent as IconRatp } from "../../../assets/icons/clients/icon-ratp.svg"
import { ReactComponent as IconBouygues } from "../../../assets/icons/clients/icon-bouygues.svg"
import { ReactComponent as IconRte } from "../../../assets/icons/clients/icon-rte.svg"
import { ReactComponent as IconEdf } from "../../../assets/icons/clients/icon-edf.svg"
import { ReactComponent as IconNantes } from "../../../assets/icons/clients/icon-nantes.svg"
import { useTranslation } from "react-i18next"

export default function PresentationPanel() {
	const [t] = useTranslation()
	const clientsIcons = [IconNantes, IconEdf, IconRatp, IconBouygues, IconRte]

	return (
		<div className="presentationPanel">
			<div className="presentationPanelContent">
				<div className="productBlock isLockpass">
					<IconLockpass className="iconProduct" />
					<p
						className="textProduct"
						dangerouslySetInnerHTML={{ __html: t("monoStepFunnel.presentationPanel.products.lockpass") }}
					/>
				</div>
				<div className="productBlock isLocktransfer isReversed">
					<IconLocktransfer className="iconProduct" />
					<p
						className="textProduct"
						dangerouslySetInnerHTML={{
							__html: t("monoStepFunnel.presentationPanel.products.locktransfer")
						}}
					/>
				</div>
				<p
					className="mainText"
					dangerouslySetInnerHTML={{ __html: t("monoStepFunnel.presentationPanel.bodyText") }}
				/>
				<div className="productBlock isLockfiles">
					<IconLockfiles className="iconProduct" />
					<p
						className="textProduct"
						dangerouslySetInnerHTML={{ __html: t("monoStepFunnel.presentationPanel.products.lockfiles") }}
					/>
				</div>
				<div className="productBlock isManagement">
					<IconManagement className="iconProduct" />
					<p
						className="textProduct"
						dangerouslySetInnerHTML={{ __html: t("monoStepFunnel.presentationPanel.products.management") }}
					/>
				</div>
			</div>
			<div className="presentationPanelFooter">
				<p
					className="text"
					dangerouslySetInnerHTML={{ __html: t("monoStepFunnel.presentationPanel.footerText") }}
				/>
				<ol className="clientsList">
					{clientsIcons.map((Icon, index) => {
						return (
							<li className="clientsListItem" key={index}>
								<Icon className="iconClient" />
							</li>
						)
					})}
				</ol>
			</div>
		</div>
	)
}
