const BASE_URL = process.env.REACT_APP_API_URL

const makeRequest = async (url, method, body = {}, noOutput = false, outputCodeOnly = false) => {
	try {
		const { id, errorCode, ...data } = body

		const res = await fetch(url, {
			method,
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data)
		})
		.catch(error => { console.log('[API] Error during request :', error) })

		const status = await res.status

		if (method.toLowerCase() === 'delete') {
			return status
		}

		if (outputCodeOnly) {
			const json = await res.json()

			return {
				code: json?.code ?? status,
				message: json?.message ?? "Error"
			}
		}

		if (!noOutput) {
			const json = await res.json()

			return json
		} else {
			return status
		}
	} catch (error) {
		console.log('[API] Error during request :', error)
	}
}

export const createUserData = (payload) => {
	if (Object.keys(payload)?.length > 0) {
		return makeRequest(`${BASE_URL}/api/external/poc_informations`, 'POST', payload)
	} else {
		return { error: '[API/CREATE] payload is empty' }
	}
}

export const updateUserData = (payload) => {
	if (payload.id && payload.uuid) {
		return makeRequest(`${BASE_URL}/api/external/poc_informations/${payload.id}`, 'PUT', payload)
	} else {
		return { code: 404, error: '[API/UPDATE] missing id or uuid in payload' }
	}
}

export const deleteUserData = ({ id, uuid }) => {
	if (id && uuid) {
		return makeRequest(`${BASE_URL}/api/external/poc_information/${id}?uuid=${uuid}`, 'DELETE')
	} else {
		return { code: 404, error: '[API/DELETE] missing id or uuid in payload' }
	}
}

export const sendUserData = (payload) => {
	if (Object.keys(payload)?.length > 0) {
		return makeRequest(`${BASE_URL}/api/external/poc`, 'POST', payload, true)
	} else {
		return { error: '[API/SEND] payload is empty' }
	}
}

export const sendMonoStepFunnelData = (payload) => {
	if (Object.keys(payload)?.length > 0) {
		return makeRequest(`${BASE_URL}/api/v2/external/poc`, 'POST', payload, false, true)
	} else {
		return { error: '[API/SEND MONO STEP FUNNEL DATA] payload is empty' }
	}
}
