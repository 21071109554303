import { useTranslation } from "react-i18next";
import { ReactComponent as IconChevronDropdown } from "../../../assets/icons/icon-chevron-dropdown.svg";
import FieldItem from "./fieldItem/FieldItem";
import { useEffect, useReducer, useRef, useState } from "react";
import ClassicButton from "./ClassicButton";
import { sendMonoStepFunnelData } from "../../../app/api";
import { useNavigate, useSearchParams } from "react-router-dom";

/* --------------------------------- GLOBALS -------------------------------- */

const FORM_FIELDS = {
	NAME: "name",
	FIRSTNAME: "firstname",
	EMAIL: "email",
	BUSINESS: "business",
	PHONE: "phone",
	COMPANY_SIZE: "companySize",
	COMPANY: "company",
	CGV: "cgv",
};

const LOCAL_STORAGE_UTM_KEYS = {
	CAMPAIGN: "monoStep_utmCampaign",
	SOURCE: "monoStep_utmSource",
	CONTENT: "monoStep_utmContent",
}

/* ------------------------------- END GLOBALS ------------------------------ */

/* --------------------------------- REDUCER -------------------------------- */

const REDUCER_ACTIONS = {
	UPDATE: "UPDATE",
};

function errorsReducer(state, action) {
	switch (action.type) {
		case REDUCER_ACTIONS.UPDATE:
			return { ...state, [action.payload.field]: action.payload.value };

		default:
			return { ...state };
	}
}

function getInitialErrorsReducerState() {
	// defines for each form field if it's invalid
	return {
		[FORM_FIELDS.NAME]: false,
		[FORM_FIELDS.FIRSTNAME]: false,
		[FORM_FIELDS.EMAIL]: false,
		[FORM_FIELDS.PHONE]: false,
		[FORM_FIELDS.COMPANY]: false,
		[FORM_FIELDS.COMPANY_SIZE]: false,
		[FORM_FIELDS.BUSINESS]: false,
		[FORM_FIELDS.CGV]: false,
	};
}

/* ------------------------------- END REDUCER ------------------------------ */

export default function FormPanel({ setIsLoaderVisible = null }) {
	const [t] = useTranslation();
	const [errorsState, errorsReducerDispatch] = useReducer(
		errorsReducer,
		getInitialErrorsReducerState(),
	);
	const [formRequestErrorText, setFormRequestErrorText] = useState("")
	const [searchParams] = useSearchParams();
	const navigate = useNavigate()
	const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
	const phoneNumberDataRef = useRef(null);
	
	function checkPhoneNumberValidity() {
		if (!phoneNumberDataRef?.current) {
			setIsPhoneNumberValid(false);

			return { isValid: false };
		}

		const { inputNumber, country } = phoneNumberDataRef.current;
		// remove 1 to ignore the "+"" sign at the beginning because the inputNumber doesn't have it
		const numberFormatLength =
			country.format?.replaceAll(" ", "")?.length - 1;
		const frNumberInvalid =
			country.iso2 === "fr" &&
				inputNumber.startsWith(`${country.dialCode}0`);
		const incorrectFormat = inputNumber?.length !== numberFormatLength;

		if (frNumberInvalid || incorrectFormat) {
			setIsPhoneNumberValid(false);

			return { isValid: false };
		}

		setIsPhoneNumberValid(true);

		return { isValid: true };
	}

	async function handleFormSubmit(event) {
		event.preventDefault();

		const formElement = event.target;
		const formDataObject = new FormData(formElement);
		const { isValid } = checkPhoneNumberValidity();
		// fields
		let checkboxFieldCatched = false;
		let formIsValid = true;

		formDataObject.keys().forEach((key) => {
			const fieldValue = formDataObject.get(key);
			const fieldStateValue = errorsState[key];

			if (!fieldValue || fieldValue?.length <= 0) {
				formIsValid = false;

				if (!fieldStateValue) {
					errorsReducerDispatch({
						type: REDUCER_ACTIONS.UPDATE,
						payload: { field: key, value: true },
					});
				}
			} else if (fieldStateValue) {
				errorsReducerDispatch({
					type: REDUCER_ACTIONS.UPDATE,
					payload: { field: key, value: false },
				});
			}

			if (key === "cgv" && fieldValue === "on") {
				checkboxFieldCatched = true;
			}
		});

		if (formIsValid && checkboxFieldCatched && isValid) {
			/* form is valid */
			const payload = {
				firstname: formDataObject.get(FORM_FIELDS.FIRSTNAME),
				lastname: formDataObject.get(FORM_FIELDS.NAME),
				email: formDataObject.get(FORM_FIELDS.EMAIL),
				organizationName: formDataObject.get(FORM_FIELDS.COMPANY),
				estimatedUser: formDataObject.get(FORM_FIELDS.COMPANY_SIZE),
				phoneNumber: formDataObject.get(FORM_FIELDS.PHONE),
				activitySector: Number(formDataObject.get(FORM_FIELDS.BUSINESS)),
				solution: "LP-LT-LF",
				utmCampaign: localStorage.getItem(LOCAL_STORAGE_UTM_KEYS.CAMPAIGN) || "",
				utmContent: localStorage.getItem(LOCAL_STORAGE_UTM_KEYS.CONTENT) || "",
				utmSource: localStorage.getItem(LOCAL_STORAGE_UTM_KEYS.SOURCE) || "",
				view: 1,
			};

			setIsLoaderVisible(true)

			const response = await sendMonoStepFunnelData(payload);

			setIsLoaderVisible(false)

			if (response.code === 201) {
				formElement.reset();

				navigate("/success-message?utm_step=success-message-mono");
			} if (response.code === 409) {
				setFormRequestErrorText(response.message)
			} else {
				setFormRequestErrorText(t("monoStepFunnel.formPanel.formErrors.serverError"))
			}
		} else if (!checkboxFieldCatched) {
			/* form is not valid */
			errorsReducerDispatch({
				type: REDUCER_ACTIONS.UPDATE,
				payload: { field: "cgv", value: true },
			});
		}

		return;
	}

	useEffect(() => {
		if (searchParams.size > 0) {
			const utmCampaign = searchParams.get("utm_campaign")
			const utmSource = searchParams.get("utm_source")
			const utmContent = searchParams.get("utm_content")

			if (!!utmCampaign) {
				localStorage.setItem(LOCAL_STORAGE_UTM_KEYS.CAMPAIGN, utmCampaign)
			}

			if (!!utmSource) {
				localStorage.setItem(LOCAL_STORAGE_UTM_KEYS.SOURCE, utmSource)
			}

			if (!!utmContent) {
				localStorage.setItem(LOCAL_STORAGE_UTM_KEYS.CONTENT, utmContent)
			}

			window.location.href = window.location.href.split("?")?.[0] || window.location.href
		}
	}, [searchParams])

	return (
		<div className={`formPanel ${formRequestErrorText ? " hasFormRequestError" : ""}`}>
			<form className="formContent" onSubmit={handleFormSubmit}>
				{formRequestErrorText?.length > 0 && <p className="formTextError">{formRequestErrorText}</p>}
				<div className="fieldsList">
					<FieldItem
						label={t("monoStepFunnel.formPanel.fields.name.label")}
						placeholder={t(
							"monoStepFunnel.formPanel.fields.name.placeholder",
						)}
						id={FORM_FIELDS.NAME}
						name={FORM_FIELDS.NAME}
						isInvalid={errorsState.name}
					/>
					<FieldItem
						label={t(
							"monoStepFunnel.formPanel.fields.firstname.label",
						)}
						placeholder={t(
							"monoStepFunnel.formPanel.fields.firstname.placeholder",
						)}
						id={FORM_FIELDS.FIRSTNAME}
						name={FORM_FIELDS.FIRSTNAME}
						isInvalid={errorsState.firstname}
					/>
					<FieldItem
						label={t(
							"monoStepFunnel.formPanel.fields.company.label",
						)}
						placeholder={t(
							"monoStepFunnel.formPanel.fields.company.placeholder",
						)}
						id={FORM_FIELDS.COMPANY}
						name={FORM_FIELDS.COMPANY}
						isInvalid={errorsState.company}
						hasMoreHeadSpaceOnMobile
					/>
					<FieldItem
						label={t(
							"monoStepFunnel.formPanel.fields.companySize.label",
						)}
						placeholder={t(
							"monoStepFunnel.formPanel.fields.companySize.placeholder",
						)}
						id={FORM_FIELDS.COMPANY_SIZE}
						name={FORM_FIELDS.COMPANY_SIZE}
						isInvalid={errorsState.companySize}
						isSelect
						optionsType="companySize"
					/>
					<FieldItem
						label={t(
							"monoStepFunnel.formPanel.fields.business.label",
						)}
						placeholder={t(
							"monoStepFunnel.formPanel.fields.business.placeholder",
						)}
						id={FORM_FIELDS.BUSINESS}
						name={FORM_FIELDS.BUSINESS}
						isInvalid={errorsState.business}
						isSelect
						hasMoreHeadSpaceOnMobile
					/>
					<FieldItem
						label={t("monoStepFunnel.formPanel.fields.email.label")}
						placeholder={t(
							"monoStepFunnel.formPanel.fields.email.placeholder",
						)}
						id={FORM_FIELDS.EMAIL}
						name={FORM_FIELDS.EMAIL}
						isInvalid={errorsState.email}
						type="email"
					/>
					<FieldItem
						label={t("monoStepFunnel.formPanel.fields.phone.label")}
						placeholder={t(
							"monoStepFunnel.formPanel.fields.phone.placeholder",
						)}
						id={FORM_FIELDS.PHONE}
						name={FORM_FIELDS.PHONE}
						isInvalid={errorsState.phone}
						isPhoneNumber
						isExpanded
						isPhoneNumberValid={isPhoneNumberValid}
						phoneNumberDataRef={phoneNumberDataRef}
						hasMoreHeadSpaceOnMobile
					/>
					<FieldItem
						label={t("monoStepFunnel.formPanel.fields.cgv.label")}
						id={FORM_FIELDS.CGV}
						name={FORM_FIELDS.CGV}
						isInvalid={errorsState.cgv}
						isCheckbox
						isExpanded
						labelNode={
							<p
								className="checkboxLabel"
								dangerouslySetInnerHTML={{
									__html: t(
										"monoStepFunnel.formPanel.fields.cgv.label",
									),
								}}
							/>
						}
						hasMoreHeadSpaceOnMobile
					/>
				</div>
				<ClassicButton
					text={t("monoStepFunnel.formPanel.submitButton")}
					Icon={IconChevronDropdown}
				/>
			</form>
		</div>
	);
}
