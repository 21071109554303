import { useTranslation } from "react-i18next"
import { ReactComponent as IconLogoLockselfV2 } from "../../assets/icons/icon-logo-lockself-v2.svg"
import ClassicButton from "./components/ClassicButton"
import { ReactComponent as IconChevronDropdown } from "../../assets/icons/icon-chevron-dropdown.svg"
import ImageLockpassAppEN from "../../assets/images/monoStepSuccess/image-lockpass-app-EN.png"
import ImageLockpassAppFR from "../../assets/images/monoStepSuccess/image-lockpass-app-FR.png"
import ImageLockpassPasswordPolicyEN from "../../assets/images/monoStepSuccess/image-lockpass-password-policy-EN.png"
import ImageLockpassPasswordPolicyFR from "../../assets/images/monoStepSuccess/image-lockpass-password-policy-FR.png"
import ImageLockpassLogoAnssiFR from "../../assets/images/monoStepSuccess/image-lockpass-logo-anssi-FR.png"
import ImageLockpassLogoAnssiEN from "../../assets/images/monoStepSuccess/image-lockpass-logo-anssi-EN.png"
import { useEffect } from "react"
import { windowReload } from "../../actions/generalUtils"

export default function MonoStepFunnelSuccess() {
	const [t, i18n] = useTranslation()
	const currentLanguage = i18n.resolvedLanguage
	const imageLockpassApp = currentLanguage === "fr" ? ImageLockpassAppFR : ImageLockpassAppEN
	const imageLockpassPasswordPolicy =
		currentLanguage === "fr" ? ImageLockpassPasswordPolicyFR : ImageLockpassPasswordPolicyEN
	const imageLockpassLogoAnssi = currentLanguage === "fr" ? ImageLockpassLogoAnssiFR : ImageLockpassLogoAnssiEN

	useEffect(() => {
		const wasReloaded = localStorage.getItem('isReloaded')

		if (!wasReloaded) {
			windowReload()
		} else {
			localStorage.removeItem('LS_funnel_wasRedirected')
		}
	}, [])

	return (
		<div className="monoStepFunnelLayout monoStepFunnelSuccess">
			<a href="https://www.lockself.com/" target="_blank" rel="noreferrer" className="iconLink">
				<IconLogoLockselfV2 className="icon" />
			</a>
			<div className="content">
				<div className="textContent">
					<p
						className="title"
						dangerouslySetInnerHTML={{ __html: t("monoStepFunnel.successScreen.title") }}
					/>
					<p className="text" dangerouslySetInnerHTML={{ __html: t("monoStepFunnel.successScreen.text") }} />
					<ClassicButton
						text={t("monoStepFunnel.successScreen.button")}
						Icon={IconChevronDropdown}
						className="hiddenOnMobile"
						isLink
					/>
					<p className="smallHiddenText">{t("monoStepFunnel.successScreen.hiddenText")}</p>
				</div>
				<div className="imageContent">
					<div className="imageLockpassAppContainer">
						<img src={imageLockpassApp} alt="" className="image" />
						<img src={imageLockpassLogoAnssi} alt="" className="image imageLogoAnssi" />
					</div>
					<div className="imageLockpassPasswordPolicyContainer">
						<img src={imageLockpassPasswordPolicy} alt="" className="image" />
					</div>
				</div>
			</div>
		</div>
	)
}
