import { createSlice } from '@reduxjs/toolkit'

const defaultState = {
	loading: false,
	errorCode: null,
	data: {
		organizationName: '',
		firstName: '',
		lastName: '',
		email: '',
		estimatedUser: '',
		solution: '',
		phoneNumber: '',
		utmSource: '',
		utmCampaign: '',
		utmContent: '',
		utmStep: "1",
		id: null,
		uuid: null
	}
}
const initialState = localStorage.getItem('userData') ? { ...JSON.parse(localStorage.getItem('userData')), loading: false } : defaultState

export const dataSlice = createSlice({
	name: 'userData',
	initialState,
	reducers: {
		initData: (state, action) => {
			const newUserData = {
				...defaultState,
				data: {
					...defaultState.data,
					...action.payload
				}
			}

			if (localStorage.getItem('isReloaded') && state.data.id !== null) localStorage.removeItem('isReloaded')
			localStorage.setItem('userData', JSON.stringify(newUserData))

			return newUserData
		},
		addData: (state, action) => {
			const statusCode = action.payload.errorCode !== undefined ? action.payload.errorCode : state.errorCode
			const { errorCode, ...data } = action.payload
			const { id, uuid } = action.payload

			const newUserData = {
				...state,
				errorCode: statusCode,
				data: {
					...data,
					id: id !== undefined ? id : (state.data.id ? state.data.id : null),
					uuid: uuid !== undefined ? uuid : (state.data.uuid ? state.data.uuid : null),
				},
			}

			if (localStorage.getItem('isReloaded')) localStorage.removeItem('isReloaded')
			localStorage.setItem('userData', JSON.stringify(newUserData))

			return newUserData
		},
		setLoading: (state, action) => {
			const newUserData = {
				...state,
				loading: action.payload
			}

			if (localStorage.getItem('userData')) localStorage.setItem('userData', JSON.stringify(newUserData))

			return newUserData
		},
		deleteData: () => {
			if (localStorage.getItem('isReloaded')) localStorage.removeItem('isReloaded')
			if (localStorage.getItem('dateBooked')) localStorage.removeItem('dateBooked')
			localStorage.removeItem('userData')

			return defaultState
		}
	}
})

export default dataSlice.reducer
