import { useState } from "react"
import { useTranslation } from "react-i18next"

export default function LanguageSelect({ isReversed = false }) {
	const { i18n } = useTranslation()
	const [isOpen, setIsOpen] = useState("")
	const langs = [
		{ name: "Français", val: "fr" },
		{ name: "English", val: "en" }
	]
	const currentLang = localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : "fr"

	// FUNCTIONS
	const handleOnClick = () => {
		setIsOpen(isOpen === "is-open" ? "" : "is-open")
	}

	const handleLgChange = lg => {
		i18n.changeLanguage(lg)
		setIsOpen("")
	}

	if (currentLang && langs.find(({ val }) => currentLang.includes(val))) {
		localStorage.setItem("i18nextLng", langs.find(({ val }) => currentLang.includes(val)).val)
	} else {
		localStorage.setItem("i18nextLng", "fr")
	}

	return (
		<div className={`language-select${isReversed ? " isReversed" : ""} ${isOpen}`}>
			<button type="button" className="btn link is-active" onClick={handleOnClick}>
				{langs.filter(({ val }) => val === currentLang)[0]?.name}
			</button>

			{langs.map(({ name, val }, index) => (
				<button type="button" key={index} className="btn link" onClick={() => handleLgChange(val)}>
					{name}
				</button>
			))}
		</div>
	)
}
