import { useTranslation } from "react-i18next"
import PhoneInput from "react-phone-input-2"
import ErrorText from "./ErrorText"

export default function PhoneNumberInput({
	id,
	name = id,
	placeholder,
	phoneNumberDataRef = null,
	isPhoneNumberValid = true
}) {
	const [t] = useTranslation()

	return (
		<>
			{!isPhoneNumberValid && <ErrorText text={t("monoStepFunnel.formPanel.fields.error.incorrectNumber")} />}
			<PhoneInput
				className="phoneNumberFieldInputContainer"
				inputProps={{
					name,
					id,
					autoFocus: false,
					className: "fieldInput isPhoneNumberFieldInput",
					placeholder
				}}
				country="fr"
				placeholder={placeholder}
				onChange={(value, data) => {
					if (!!phoneNumberDataRef) {
						phoneNumberDataRef.current = { inputNumber: value, country: data }
					}
				}}
			/>
		</>
	)
}
