import i18n from "../../../i18n"

export default function ClassicButton({ text, Icon, className = "", isLink = false }) {
	if (isLink) {
		const currentLanguage = i18n.resolvedLanguage
		const url = `https://support.lockself.com/hc/${currentLanguage.includes('fr') ? 'fr' : "en-us"}/sections/4411329440914-Installer-LockPass`	

		return (<a href={url} target='_blank' rel="noreferrer" className={`formButton${className ? " " + className : ""}`}>
			{text && text}
			{Icon && <Icon className="icon" />}
		</a>)

	}

	return (
		<button type="submit" className={`formButton${className ? " " + className : ""}`}>
			{text && text}
			{Icon && <Icon className="icon" />}
		</button>
	)
}
