import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import userDataReducer from './../pages/slice'
import pagesSagas from '../pages/sagas'

const sagaMiddleware = createSagaMiddleware()
const store = configureStore({
	reducer: combineReducers({
		userData: userDataReducer
	}),
	middleware: [...getDefaultMiddleware({ serializableCheck: false }), sagaMiddleware]
})

sagaMiddleware.run(pagesSagas)

export default store
